import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Header = (props) => {
  const headerType = props.headerType ? props.headerType : "";

  const [headerTypeCss, setHeaderTypeCss] = useState(
    "header-nav-box header-nav-box-6"
  );

  useEffect(() => {
    if (headerType)
      setHeaderTypeCss(
        "header-nav-box header-nav-box-inner-page header-nav-box-3"
      );
  }, [headerType]);

  // if (headerType == "white_header") {
  //   setHeaderTypeCss(
  //     "header-nav-box header-nav-box-inner-page header-nav-box-3"
  //   );
  // }
  return (
    <React.Fragment>
      <>
        {/*====== PRELOADER PART START ======*/}
        {/* <div className="loader-wrap">
          <div className="preloader">
            <div className="preloader-close">Preloader Close</div>
          </div>
          <div className="layer layer-one">
            <span className="overlay" />
          </div>
          <div className="layer layer-two">
            <span className="overlay" />
          </div>
          <div className="layer layer-three">
            <span className="overlay" />
          </div>
        </div> */}
        {/*====== PRELOADER PART ENDS ======*/}

        {/*====== OFFCANVAS MENU PART START ======*/}
        <div className="off_canvars_overlay"></div>
        <div className="offcanvas_menu">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="offcanvas_menu_wrapper">
                  <div className="canvas_close">
                    <a href="javascript:void(0)">
                      <i className="fa fa-times" />
                    </a>
                  </div>
                  <div className="offcanvas-brand text-center mb-40">
                    <img src="assets/images/logo.png" alt="" />
                  </div>
                  <div id="menu" className="text-left ">
                    <ul className="offcanvas_main_menu">
                      <li className="menu-item-has-children active">
                        <a href="#">Home</a>
                        <ul className="sub-menu">
                          <li>
                            <a href="index.html">Home 1</a>
                          </li>
                          <li>
                            <a href="index-2.html">Home 2</a>
                          </li>
                          <li>
                            <a href="index-3.html">Home 3</a>
                          </li>
                          <li>
                            <a href="index-4.html">Home 4</a>
                          </li>
                          <li>
                            <a href="index-5.html">Home 5</a>
                          </li>
                          <li>
                            <a href="index-6.html">Home 6</a>
                          </li>
                          <li>
                            <a href="index-7.html">Home 7</a>
                          </li>
                          <li>
                            <a href="index-8.html">Home 8</a>
                          </li>
                          <li>
                            <a href="index-9.html">Home 9</a>
                          </li>
                          <li>
                            <a href="index-10.html">Home 10</a>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-has-children active">
                        <a href="#service">Service</a>
                      </li>
                      <li className="menu-item-has-children active">
                        <a href="#features">Feature</a>
                      </li>
                      <li className="menu-item-has-children active">
                        <a href="#testimonial">Testimonial</a>
                      </li>
                      <li className="menu-item-has-children active">
                        <a href="#">News</a>
                        <ul className="sub-menu">
                          <li>
                            <a href="index.html">news page</a>
                          </li>
                          <li>
                            <a href="index-2.html">Single News</a>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-has-children active">
                        <a href="contact.html">Contact</a>
                      </li>
                    </ul>
                  </div>
                  <div className="offcanvas-social">
                    <ul className="text-center">
                      <li>
                        <a href="%24.html">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="%24.html">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="%24.html">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="%24.html">
                          <i className="fab fa-dribbble" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-widget-info">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fal fa-envelope" /> support@appie.com
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fal fa-phone" /> +(642) 342 762 44
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fal fa-map-marker-alt" /> 442 Belle
                          Terre St Floor 7, San Francisco, AV 4206
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====== OFFCANVAS MENU PART ENDS ======*/}
        {/*====== PART START ======*/}
        <header className="appie-header-area appie-sticky">
          <div className="container">
            <div className={headerTypeCss}>
              <div className="row align-items-center">
                <div className="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
                  <div className="appie-logo-box">
                    <a href="/">
                      <img src="/images/logo.png" alt="logo" width={100} />
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 col-md-1 col-sm-1 order-3 order-sm-2">
                  <div className="appie-header-main-menu">
                    <ul>
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>
                        <a href="/about_us">About Us</a>
                      </li>
                      <li>
                        <a href="/our_services">Our Services</a>
                      </li>
                      <li>
                        <a href="#">
                          Pages <i className="fal fa-angle-down" />
                        </a>
                        <ul className="sub-menu">
                          <li>
                            <a href="terms_and_conditions">T&Cs</a>
                          </li>
                          <li>
                            <a href="privacy_policy">Privacy Policy</a>
                          </li>
                          <li>
                            <a href="refund_policy">Refund Policy</a>
                          </li>
                          <li>
                            <a href="grievance_policy">Grievance Policy</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="/contact_us">Contact</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4  col-md-7 col-sm-6 col-6 order-2 order-sm-3">
                  <div className="appie-btn-box text-right">
                    <a
                      className="login-btn"
                      href="https://app.rechargeways.in/login"
                      target="_blank"
                    >
                      <i className="fal fa-user" /> Login
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/*====== PART ENDS ======*/}
      </>
    </React.Fragment>
  );
};

export default Header;
