import React, { useEffect, useState, useContext, useRef } from "react";
import Swal from "sweetalert2";
import Footer from "../Templete/Footer";
import Header from "../Templete/Header";

import FormService from "../../Services/FormService";
import LoadingContext from "../../Store/loading-context";
import HttpError from "../../utill/HttpError";

const ContactUs = () => {
  useEffect(() => {
    document.title = "Contact Us - Recharge Ways";
  }, []);

  const loadingCtx = useContext(LoadingContext);

  const [message, setMessage] = useState();

  const formRef = useRef();
  const fnameRef = useRef();
  const lnameRef = useRef();
  const emailRef = useRef();
  const mobileRef = useRef();
  const subjectRef = useRef();

  const submitHandler = async () => {
    const fname = fnameRef.current.value;
    const lname = lnameRef.current.value;
    const email = emailRef.current.value;
    const mobile = mobileRef.current.value;
    const subject = subjectRef.current.value;

    if (fname == "") {
      Swal.fire("Opps!", "Please Enter First Name", "error");
      return;
    }

    if (lname == "") {
      Swal.fire("Opps!", "Please Enter Last Name", "error");
      return;
    }

    if (email == "") {
      Swal.fire("Opps!", "Please Enter Email ID", "error");
      return;
    }

    if (mobile == "") {
      Swal.fire("Opps!", "Please Enter Mobile Number", "error");
      return;
    }

    if (subject == "") {
      Swal.fire("Opps!", "Please Enter Subject", "error");
      return;
    }

    if (message == "") {
      Swal.fire("Opps!", "Please Enter Message", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const responseData = await FormService.contactForm(
        fname + " " + lname,
        email,
        mobile,
        subject,
        message
      );

      const status = responseData.status;
      const message2 = responseData.message;

      if (status === "SUCCESS") {
        formRef.current.reset();
        Swal.fire("Success!", message2, "success");
      } else {
        Swal.fire("Opps!", message2, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <div class="page_wrapper">
        <Header headerType="white_header" />

        <>
          {/*====== APPIE PAGE TITLE PART START ======*/}
          <div className="appie-page-title-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="appie-page-title-item">
                    <h3 className="title">Contact Us</h3>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="/">Home</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Contact Us
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*====== APPIE PAGE TITLE PART ENDS ======*/}
          {/* Contact Start */}
          <section className="contact-section">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <div className="contact--info-area">
                    <h3>Get in touch</h3>
                    <p>
                      Have questions or need assistance? We're here to help!
                    </p>
                    <div className="single-info">
                      <h5>Registered Office</h5>
                      <p>
                        <i className="fal fa-home" />
                        No - 3 Kachari Veti Top Silbori, Dalgaon,
                        <br /> Darrang, Assam - 784114
                      </p>
                    </div>
                    <div className="single-info">
                      <h5>Phone</h5>
                      <p>
                        <i className="fal fa-phone" />
                        +91 8011-3915-06
                      </p>
                    </div>
                    <div className="single-info">
                      <h5>Support</h5>
                      <p>
                        <i className="fal fa-envelope" />
                        help@rechargeways.in
                      </p>
                    </div>
                    <div className="ab-social">
                      <h5>Follow Us</h5>
                      <a className="fac" href="#">
                        <i className="fab fa-facebook-f" />
                      </a>
                      <a className="twi" href="#">
                        <i className="fab fa-twitter" />
                      </a>
                      <a className="you" href="#">
                        <i className="fab fa-youtube" />
                      </a>
                      <a className="lin" href="#">
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="contact-form">
                    <h4>Let’s Connect</h4>
                    <p>Please fill the below form, We will get back to you.</p>
                    <form method="post" className="row">
                      <div className="col-md-6">
                        <input
                          type="text"
                          name="f-name"
                          placeholder="First Name"
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          name="l-name"
                          placeholder="Last Name"
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email Address"
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="number"
                          name="phone"
                          placeholder="Phone Number"
                        />
                      </div>
                      <div className="col-md-12">
                        <input
                          type="text"
                          name="suject"
                          placeholder="Subject"
                        />
                      </div>
                      <div className="col-md-12">
                        <textarea
                          name="message"
                          placeholder="How can we help?"
                          defaultValue={""}
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="condition-check">
                          <input id="terms-conditions" type="checkbox" />
                          <label htmlFor="terms-conditions">
                            I agree to the{" "}
                            <a href="#">Terms &amp; Conditions</a>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6 text-right">
                        <input
                          type="submit"
                          name="submit"
                          defaultValue="Send Message"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Contact End */}
        </>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default ContactUs;
