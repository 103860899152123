import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const StartHero = () => {
  return (
    <React.Fragment>
      <>
        {/*====== APPIE HERO PART START ======*/}
        <section className="appie-hero-area appie-hero-6-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="appie-hero-thumb-6">
                  <div
                    className="thumb wow animated fadeInUp"
                    data-wow-duration="1000ms"
                    data-wow-delay="600ms"
                  >
                    <img
                      src="/images/recharge-banner-2.png"
                      alt="banner-recharge"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 pl-5">
                <div className="appie-hero-content appie-hero-content-6">
                  <span>Welcome To Recharge Ways.</span>
                  <h2 className="appie-title">
                    Recharge Instantly, Anytime, Anywhere!
                  </h2>
                  <p>
                    Experience fast, secure mobile and DTH recharges with
                    Recharge Ways. Simple, rewarding, and 24/7 support at your
                    fingertips.
                  </p>
                  <ul>
                    <li>
                      <a
                        className="item-2"
                        href="https://play.google.com/store/apps/details?id=com.fispl.rechargeways"
                        target="_blank"
                      >
                        <i className="fab fa-google-play" />{" "}
                        <span>
                          Available on the <span>Google Play</span>
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*====== APPIE HERO PART ENDS ======*/}
      </>
    </React.Fragment>
  );
};

export default StartHero;
