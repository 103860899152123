import React, { useEffect } from "react";
import Footer from "../Templete/Footer";
import Header from "../Templete/Header";
import "./style.css";

import Testimonials from "../Home/Testimonials";
import GetInTouch from "../Home/GetInTouch";

const TermsAndConditions = () => {
  useEffect(() => {
    document.title = "Terms and Conditions";
  }, []);

  return (
    <React.Fragment>
      <div class="page_wrapper">
        <Header headerType="white_header" />

        <div className="appie-page-title-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-page-title-item">
                  <h3 className="title">Terms and Conditions</h3>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/">Home</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Terms and Conditions
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <>
          {/* Blog Details Block */}
          <section class="appie-service-details-area pt-100 pb-100">
            <div class="container">
              <h4>Terms and Conditions for Recharge Ways</h4>

              <div className="info">
                <h5>1. Introduction</h5>
                <p>
                  Welcome to the Recharge Ways. These Terms and Conditions
                  ("Terms") govern your access to and use of our application and
                  services ("Services"). By registering for, accessing, or using
                  the Services, you agree to comply with these Terms. If you do
                  not agree with any part of these Terms, you must discontinue
                  use of our Services immediately.
                </p>

                <h5>2. Definitions</h5>
                <p>For the purposes of these Terms:</p>
                <ul>
                  <li>
                    <strong>"Company," "we," "us,"</strong> and{" "}
                    <strong>"our"</strong> refer to Recharge Ways.
                  </li>
                  <li>
                    <strong>"User," "you,"</strong> and <strong>"your"</strong>{" "}
                    refer to the individual accessing or using our Services.
                  </li>
                  <li>
                    <strong>"Account"</strong> means the account you create to
                    access our Services.
                  </li>
                  <li>
                    <strong>"Recharge Services"</strong> refer to mobile, DTH,
                    and other recharge services provided through our app.
                  </li>
                </ul>

                <h5>3. Account Registration</h5>
                <p>
                  To use our Services, you must register for an account by
                  providing accurate and complete information. You agree to keep
                  your account information updated at all times. You are solely
                  responsible for maintaining the confidentiality of your
                  account credentials and for all activities that occur under
                  your account.
                </p>

                <h5>4. Use of Services</h5>
                <p>By using our Services, you agree to the following:</p>
                <ul>
                  <li>
                    1. You will not use the Services for any unlawful,
                    fraudulent, or malicious activities.
                  </li>
                  <li>
                    2. You will provide accurate information when using our
                    Services and ensure that all transactions comply with
                    applicable laws and regulations.
                  </li>
                  <li>
                    3. You will not interfere with, disrupt, or attempt to gain
                    unauthorized access to the Services, user accounts, or any
                    networks connected to the Services.
                  </li>
                  <li>
                    4. You will comply with all additional policies and
                    guidelines provided by the Company.
                  </li>
                </ul>

                <h5>5. Payment and Charges</h5>
                <p>
                  All payments for recharge transactions must be made through
                  the payment methods available in the app. You agree to pay all
                  applicable fees, charges, and taxes associated with your use
                  of the Services. The Company reserves the right to change the
                  pricing and payment policies at any time, with prior notice to
                  users.
                </p>

                <h5>6. Refund Policy</h5>
                <p>
                  Refunds for transactions made through our Services are
                  governed by our <a href="refund_policy">Refund Policy</a>.
                  Refunds will only be issued under specific conditions, such as
                  failed or unsuccessful transactions. Please review our Refund
                  Policy for detailed information on eligibility, procedures,
                  and timelines.
                </p>

                <h5>7. Commission and Earnings</h5>
                <p>
                  If you are a partner or affiliate earning commissions through
                  our Services, the following terms apply:
                </p>
                <ul>
                  <li>
                    1. Commissions will be credited to your account as per the
                    terms specified in your partnership agreement.
                  </li>
                  <li>
                    2. Commissions are subject to deductions for any refunds,
                    chargebacks, or penalties incurred by the Company.
                  </li>
                  <li>
                    3. The Company reserves the right to modify commission rates
                    and payout schedules at any time, with prior notice to
                    partners.
                  </li>
                </ul>

                <h5>8. Intellectual Property Rights</h5>
                <p>
                  All content, logos, trademarks, and other intellectual
                  property used in connection with the Services are the property
                  of Recharge Ways or its licensors. You may not use, reproduce,
                  modify, or distribute any of our intellectual property without
                  prior written permission from the Company.
                </p>

                <h5>9. Limitation of Liability</h5>
                <p>
                  To the maximum extent permitted by law, Recharge Ways and its
                  affiliates, officers, directors, employees, and agents shall
                  not be liable for any direct, indirect, incidental, special,
                  or consequential damages arising out of or in connection with
                  your use of the Services, including but not limited to damages
                  for loss of profits, data, goodwill, or other intangible
                  losses.
                </p>

                <h5>10. Indemnification</h5>
                <p>
                  You agree to indemnify and hold harmless Recharge Ways and its
                  affiliates, officers, directors, employees, and agents from
                  any claims, liabilities, damages, losses, and expenses
                  (including legal fees) arising out of your use of the
                  Services, your violation of these Terms, or your infringement
                  of any third-party rights.
                </p>

                <h5>11. Termination</h5>
                <p>
                  The Company reserves the right to terminate or suspend your
                  account and access to the Services, with or without notice,
                  for any reason, including but not limited to violations of
                  these Terms, fraudulent activities, or unlawful conduct. Upon
                  termination, you must immediately cease all use of the
                  Services and any associated content.
                </p>

                <h5>12. Governing Law and Jurisdiction</h5>
                <p>
                  These Terms and Conditions are governed by and construed in
                  accordance with the laws of Assam, India. Any disputes arising
                  out of or in connection with these Terms will be subject to
                  the exclusive jurisdiction of the courts of Assam, India.
                </p>

                <h5>13. Modifications to Terms</h5>
                <p>
                  Recharge Ways reserves the right to modify these Terms and
                  Conditions at any time. Users will be notified of significant
                  changes through app notifications or email. Your continued use
                  of the Services after any such modifications signifies your
                  acceptance of the revised Terms.
                </p>

                <h5>14. Privacy Policy</h5>
                <p>
                  Your use of the Services is also governed by our{" "}
                  <a href="privacy_policy">Privacy Policy</a>, which outlines
                  how we collect, use, and protect your personal information.
                  Please review the Privacy Policy carefully before using our
                  Services.
                </p>

                <h5>15. Contact Information</h5>
                <p>
                  If you have any questions or concerns regarding these Terms
                  and Conditions, please contact our support team at
                  help@rechargeways.com. We are committed to addressing your
                  inquiries promptly and efficiently.
                </p>
              </div>
            </div>
          </section>
        </>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default TermsAndConditions;
