import React from "react";

const AboutCompany = () => {
  return (
    <React.Fragment>
      {/* App-Solution-Section-Start */}

      <section className="appie-features-9-area pt-100" id="features">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6">
              <div className="appie-features-9-area-thumb">
                <img src="images/about-us.png" alt="about-us" />
              </div>
            </div>
            <div className="col-xl-6 text-center text-xl-left pt-5 pt-xl-0">
              <div className="appie-features-9-area-heading">
                <h2>Introducing Recharge Way</h2>
              </div>
              <p className="text-justify">
                At Recharge Ways, our mission is to make mobile and DTH
                recharges effortless, secure, and rewarding. Established with a
                vision to transform the recharge landscape, Recharge Ways is
                dedicated to bringing reliability, speed, and value into every
                transaction. We recognize the essential role recharges play in
                keeping people connected, and our goal is to provide a platform
                that serves as a one-stop solution for all recharge needs.
              </p>
              <p className="text-justify pt-2 pb-4">
                Recharge Ways offers a wide array of services, from mobile and
                DTH recharges to data top-ups, catering to both individual users
                and businesses. We’ve designed a user-friendly platform that is
                easy to navigate and offers seamless processing, saving our
                users both time and effort. With Recharge Ways, recharging isn’t
                just simple—it’s also rewarding, with cashback offers and
                incentives that make every transaction valuable.
              </p>
              <a href="/about_us" className="main-btn main-btn-3">
                Read More
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* App-Solution-Section-end */}
    </React.Fragment>
  );
};

export default AboutCompany;
