import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <React.Fragment>
      <>
        {/*====== APPIE FOOTER PART START ======*/}
        <section className="appie-footer-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="footer-about-widget footer-about-widget-6">
                  <div className="logo">
                    <a href="#">
                      <img src="/images/logo.png" alt="logo" width={100} />
                    </a>
                  </div>
                  <p>
                    Recharge Ways: Fast, secure mobile & DTH recharges made
                    easy. Recharge anytime, anywhere!
                  </p>
                  <a href="about_us">
                    Read More <i className="fal fa-arrow-right" />
                  </a>
                  <div className="social mt-30">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-whatsapp" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-youtube" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="footer-navigation footer-navigation-6">
                  <h4 className="title">Our Services</h4>
                  <ul>
                    <li>
                      <a href="our_services">Mobile Recharge</a>
                    </li>
                    <li>
                      <a href="our_services">DTH Recharge</a>
                    </li>
                    <li>
                      <a href="our_services">Google Play Recharge</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="footer-navigation footer-navigation-6">
                  <h4 className="title">Useful Links</h4>
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="about_us">About Us</a>
                    </li>
                    <li>
                      <a href="contact_us">Contact Us</a>
                    </li>
                    <li>
                      <a href="terms_and_conditions">Terms and Conditions</a>
                    </li>
                    <li>
                      <a href="privacy_policy">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="refund_policy">Refund Policy</a>
                    </li>
                    <li>
                      <a href="grievance_policy">Grievance Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="footer-widget-info">
                  <h4 className="title">Get In Touch</h4>
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fal fa-envelope" /> help@rechargeways.in
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fal fa-phone" /> +91 8011-3915-06
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fal fa-map-marker-alt" /> No - 3 Kachari
                        Veti Top Silbori, Dalgaon, Darrang Assam - 784114
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                  <div className="apps-download-btn">
                    <ul>
                      <li>
                        <a
                          className="item-2"
                          href="https://play.google.com/store/apps/details?id=com.fispl.rechargeways"
                        >
                          <i className="fab fa-google-play" /> Download Android
                          App
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="copyright-text">
                    <p>Copyright © 2024 Recharge Ways. All rights reserved.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*====== APPIE FOOTER PART ENDS ======*/}
        {/*====== APPIE BACK TO TOP PART ENDS ======*/}
        <div className="back-to-top back-to-top-6">
          <a href="#">
            <i className="fal fa-arrow-up" />
          </a>
        </div>
        {/*====== APPIE BACK TO TOP PART ENDS ======*/}
      </>
    </React.Fragment>
  );
};

export default Footer;
